import { CancelToken } from 'axios';
import { omit } from 'lodash';
import { appConfig } from '../config/app-config';
import {
  GET_STORES_LIMIT,
  ProductPermissions,
  statesOfUS,
} from '../constants/constants';
import { Store } from '../domain/Store';
import {
  StoreDetailResponse,
  StoreConfigsPayload,
  StoreConfigsResponse,
  State,
} from '../types/types';
import { client } from './client';

export const getStoreDetailByNumber = (
  storeNumber: string,
  cancelToken?: CancelToken
): Promise<StoreDetailResponse> => {
  return client(
    `stores/${storeNumber}/details`,
    {
      method: 'GET',
      headers: {
        storenumber: storeNumber,
      },
      cancelToken,
    },
    appConfig.apiUrls.am,
    true
  );
};

export const getStoreConfigs = (
  payload: StoreConfigsPayload
): Promise<StoreConfigsResponse[]> => {
  return client(`/config/get-store-profile`, {
    method: 'POST',
    body: { ...payload },
  });
};

const addStateToStore = (store: any): Store => {
  const state: State = {
    abbreviation: store.storeState,
    name:
      statesOfUS.find((state: State) => state.abbreviation === store.storeState)
        ?.name || '',
  };

  return omit(
    {
      ...store,
      state,
    },
    ['storeState']
  ) as Store;
};

export const getUserPermissions = (
  productType = 'RPDMANPO'
): Promise<ProductPermissions[]> =>
  client(`users/current/permissions/${productType}`, { method: 'GET' });

export const getStores = (
  term = '',
  cancelToken: CancelToken,
  limit = GET_STORES_LIMIT
) => {
  const params: any = {};

  if (term) {
    params.q = `storeNum[:includes]=${term}`;
  }

  params.limit = limit;

  return client(
    'users/current/stores',
    {
      method: 'GET',
      params,
      cancelToken,
    },
    appConfig.apiUrls.container
  ).then((stores: Store[]) => {
    return stores.map(addStateToStore);
  });
};
