import {
  makeStyles,
  RACBreadcrumb,
  RACBreadcrumbLinkType,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { AppRoute } from '../../config/route-config';

export interface BreadcrumbProps {
  purchaseOrderId?: string;
  onClick?: (event: any, href: string) => void;
}

const useStyles = makeStyles((theme: any) => ({
  breadcrumbContainer: {
    width: '100%',
    height: theme.typography.pxToRem(45),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

export const initialLinks = [
  {
    href: `/manualpo${AppRoute.POs}`,
    label: 'Manual Purchase Order',
  },
];

export const initialLinksM = [
  {
    href: `/menu${AppRoute.POs}`,
    label: 'Manual Purchase Order',
  },
];

const getLinks = () => {
  if (window.location.pathname.includes('menu')) {
    return initialLinksM;
  }
  return initialLinks;
};

export const PODetailsBreadcrumb = ({
  purchaseOrderId,
  onClick,
}: BreadcrumbProps) => {
  const [links, setLinks] = React.useState<RACBreadcrumbLinkType[]>(getLinks());
  const classes = useStyles();

  useEffect(() => {
    if (purchaseOrderId) {
      setLinks([
        ...links,
        {
          label: purchaseOrderId,
        },
      ]);
    } else {
      const createPOLink = {
        label: 'Create PO',
      };
      setLinks([...getLinks(), createPOLink]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderId]);
  return (
    <div className={clsx(classes.breadcrumbContainer)}>
      <RACBreadcrumb links={links} onClick={onClick} />
    </div>
  );
};
