import {
  makeStyles,
  RACButton,
  RACCOLOR,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTooltip,
} from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { generateCancelToken, getCancelTokenSource } from '../../api/client';
import { ReactComponent as ArrowRight } from '../../assets/img/Accordion-close.svg';
import { ReactComponent as ArrowDown } from '../../assets/img/Accordion-down.svg';
import { AppRoute } from '../../config/route-config';
import {
  CANCELLED_SUCCESS,
  CUSTOM_API_MESSAGE_FETCH_PO,
  GET_STORES_LIMIT,
  // POStatusTypes,
  INITIAL_OFFSET,
  MANUAL_PO_API_LIMIT,
  purchaseOrderStatusTypes,
  PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL,
  REDIRECT_ACTION,
  REDIRECT_ORIGIN,
  REDIRECT_PARAM_VALUE_KEY,
  SubmittdPOStatusTypes,
} from '../../constants/constants';
import { getDraftPurchaseOrders } from '../../context/draft/draft-pos-thunks';
import { DraftPosContext } from '../../context/draft/DraftPosContext';
import { SubmittedPosContext } from '../../context/submitted/SubmittedPosContext';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../domain/PurchaseOrder/PurchaseOrder';
import { formatMoney, noOp } from '../../utils/utils';
import { DeletePOConfirmModal } from './DeletePOConfirmModal';
import { CancelPOConfirmModal } from './CancelPOConfirmModal';
import { AlertModal } from './AlertModal';
import { getSubmittedPurchaseOrders } from '../../context/submitted/submitted-pos-thunks';
import { RACInfiniteScroll } from '../../common/RACInfiniteScroll/RACInfiniteScroll';
import {
  GetPurchaseOrderRequest,
  GetPurchaseOrderResponse,
  POSearchFilter,
} from '../../types/types';
import { getPurchaseOrdersByStatus } from '../../api/purchaseOrders';
import { StoresStateContext } from '../../context/stores/StoresProvider';
import { useUserStateContext } from '../../context/user/user-contexts';

export const testId = 'poTableTestId';

const COLUMN_TEXT_LIMIT = 30;
const DESCRIPTION_COLUMN_TEXT_LIMIT = 40;
export const DELETE_SUCCESS = 'Manual  PO deleted successfully.';
export interface POTableProps {
  isLoading: boolean;
  selectedPurchaseOrderType: string;
  filter: POSearchFilter;
  hasError?: boolean;
}
const useClasses = makeStyles((theme: any) => ({
  paddingTopBottom8: {
    background: RACCOLOR.WHITE,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    '@media print': {
      paddingTop: '.2rem',
      paddingBottom: '.0rem',
    },
  },
  contentHeight: {
    height: theme.typography.pxToRem(330),
  },
  defaultCursor: {
    cursor: 'default',
  },
  poNumber: {
    color: RACCOLOR.DEEP_SKY_BLUE,
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%',
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  details: {
    position: 'relative',
    height: theme.typography.pxToRem(81),
  },
  noPadding: {
    padding: '0 !important',
  },
  cancelButton: {
    backgroundColor: RACCOLOR.LINEN,
    padding: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(90),
    height: theme.typography.pxToRem(35),
    marginLeft: theme.typography.pxToRem(5),
    color: RACCOLOR.PUNCH,
    '&:hover': {
      backgroundColor: RACCOLOR.LINEN,
    },
    '&:disabled': {
      backgroundColor: RACCOLOR.ICEWIND_DALE,
      color: RACCOLOR.POMPEII_ASH,
    },
  },
  receiveButton: {
    backgroundColor: RACCOLOR.LINEN,
    padding: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(90),
    height: theme.typography.pxToRem(35),
    color: RACCOLOR.PUNCH,
    marginLeft: theme.typography.pxToRem(5),
    '&:hover': {
      backgroundColor: RACCOLOR.LINEN,
    },
  },
  disableButton: {
    width: theme.typography.pxToRem(90),
    height: theme.typography.pxToRem(35),
    padding: theme.typography.pxToRem(10),
    '&:disabled': {
      backgroundColor: RACCOLOR.ICEWIND_DALE,
      color: RACCOLOR.POMPEII_ASH,
    },
  },
  submittedButton: {
    width: theme.typography.pxToRem(97),
    height: theme.typography.pxToRem(35),
    padding: theme.typography.pxToRem(10),
    '&:hover': {
      backgroundColor: RACCOLOR.LINEN,
    },
    '&:disabled': {
      backgroundColor: RACCOLOR.PUNCH,
      color: 'white',
    },
  },
  innerTablePadding: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      3
    )} ${theme.typography.pxToRem(10)}`,
  },
}));

export const tableHeaderlist = [
  '',
  'PO#',
  'Store#',
  'Supplier',
  'Department',
  'Sub Department',
  'Condition',
  'Brand',
  'Bracket',
  'Total Cost',
  'Qty',
  'Status',
  'Actions',
];

const getDataById = (data: [], parentId: string): any[] | undefined => {
  return (
    data &&
    data.filter(({ id }: { id: string }) => parentId.split('.')[0] === id)
  );
};

const getDepartmentById: any = (
  classificationsByDepts: any,
  purchaseOrder: PurchaseOrder
) => {
  if (!classificationsByDepts) {
    return null;
  }
  return getDataById(classificationsByDepts, purchaseOrder.department || '');
};

const trimText = (text: string | undefined) => {
  if (!text) return '';

  if (text.length > COLUMN_TEXT_LIMIT)
    return `${text.substring(0, COLUMN_TEXT_LIMIT)}...`;

  return text;
};

const trimTextWithTooltip = (
  text: string | undefined,
  length: number = COLUMN_TEXT_LIMIT
) => {
  if (!text) return '';

  if (text.length > length) {
    return (
      <RACTooltip title={text}>
        <span>{trimText(text)}</span>
      </RACTooltip>
    );
  }

  return text;
};

const getSubDepartmentById: any = (
  classificationsByDepts: any,
  purchaseOrder: PurchaseOrder
) => {
  const departments = getDepartmentById(classificationsByDepts, purchaseOrder);

  if (departments?.length) {
    return getDataById(
      departments[0].subDepartments,
      purchaseOrder.subDepartment || ''
    );
  }
};

const getBracketById: any = (
  classificationsByDepts: any,
  purchaseOrder: PurchaseOrder
) => {
  const subDepartments = getSubDepartmentById(
    classificationsByDepts,
    purchaseOrder
  );

  if (subDepartments?.length) {
    return getDataById(subDepartments[0].brackets, purchaseOrder.bracket || '');
  }
};

export const POTable = ({
  isLoading,
  selectedPurchaseOrderType,
  filter,
}: POTableProps) => {
  const classes = useClasses();
  const history = useHistory();
  const initialCallToken = useRef<CancelTokenSource>();
  const [hasApiError, setApiHasError] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);

  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  const {
    classifications: { classificationsByDepts },
    poStores,
    selectedPOCleared,
  } = useGlobalContext();

  const { dispatch } = useContext(DraftPosContext);
  const { dispatch: submittedPODispatch } = useContext(SubmittedPosContext);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedPO, setSelectedPO] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(isLoading);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [reloadPOList, setReloadPOList] = useState<boolean>(false);
  const [storeSelectionIsRequired, setStoreSelectionIsRequired] =
    useState<boolean>(false);
  const { poNumber, toDate, fromDate } = filter;

  const { stores: initialStores } = useContext(StoresStateContext);
  const { user } = useUserStateContext();

  const onToggleExpand = (newIndex: number) => {
    if (newIndex === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(newIndex);
    }
  };

  const renderTableHead = () => (
    <>
      {tableHeaderlist.map((col, index) => {
        return (
          <RACTableCell
            key={index}
            classes={{
              root: clsx(classes.paddingTopBottom8),
            }}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );

  const renderTableContent = () => {
    if (!purchaseOrders?.length) return <></>;

    return renderTableContentFilled();
  };

  const source: CancelTokenSource = generateCancelToken();

  const handleDeletePOModalClose = () => {
    setSelectedPO('');
    setOpenDeleteModal(false);
  };

  const handleDeletePOModalResolve = (value: string) => {
    if (value === 'success') {
      setAlertMessage(DELETE_SUCCESS);
      setAlertType('success');
    } else {
      setAlertMessage(value);
      setAlertType('error');
    }

    getDraftPurchaseOrders(dispatch)(poStores, source.token);
    setOpenDeleteModal(false);
    setSelectedPO('');
    setOpenAlertModal(true);
  };

  const handleCancelPOModalClose = () => {
    if (alertType === 'success') {
      setPurchaseOrders([]);
      setReloadPOList(true);
    }
    setSelectedPO('');
    setOpenCancelModal(false);
    setOpenAlertModal(false);
  };

  const handleCancelPOModalResolve = (value: string) => {
    value === 'success'
      ? setAlertMessage(CANCELLED_SUCCESS)
      : setAlertMessage(value);
    value === 'success' ? setAlertType('success') : setAlertType('error');
    getSubmittedPurchaseOrders(submittedPODispatch)(poStores, source.token);
    setOpenCancelModal(false);
    setSelectedPO('');
    setOpenAlertModal(true);
  };

  const onReceiveClick = (poNumber: string) => {
    history.push(
      `${AppRoute.ReceivingPO}?${REDIRECT_PARAM_VALUE_KEY.PO_NUMBER}=${poNumber}&origin=${REDIRECT_ORIGIN.MANUAL_PO_LIST}&action=${REDIRECT_ACTION.RECEIVE_PO}`
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContentFilled = () => (
    <>
      {purchaseOrders?.map((obj: any, index) => {
        const hasMultiPleStores = obj?.allocations?.length > 1;
        const isDraftPO = obj.poStatus === PoStatus.DRAFT;
        const storeNum =
          isDraftPO && hasMultiPleStores ? 'Multi' : obj.storeNumber;

        const purchaseOrderDetails = obj?.purchaseOrderDetails[0];

        const generateActions = (selectedPOId: string | undefined) => {
          if (isDraftPO && selectedPOId) {
            return (
              <RACButton
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={() => {
                  setSelectedPO(selectedPOId);
                  setOpenDeleteModal(true);
                }}
              >
                Delete
              </RACButton>
            );
          }

          if (obj.isCanceled) {
            return (
              <RACButton
                disabled
                variant="contained"
                color="primary"
                onClick={noOp}
                className={classes.disableButton}
              >
                Cancelled
              </RACButton>
            );
          } else if (
            selectedPOId &&
            obj.poStatusType === PoStatus.SUBMITTED_TO_VENDOR
          ) {
            return (
              <>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.cancelButton}
                  onClick={() => {
                    setSelectedPO(selectedPOId);
                    setOpenCancelModal(true);
                  }}
                >
                  Cancel
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.receiveButton}
                  onClick={() => onReceiveClick(obj.externalPoNumber)}
                >
                  Receive
                </RACButton>
              </>
            );
          } else if (
            selectedPOId &&
            (obj.poStatusType === PoStatus.RECEIVED ||
              obj.poStatusType === PoStatus.PARTIALLY_RECEIVED)
          ) {
            return (
              <>
                {obj.poStatusType === PoStatus.PARTIALLY_RECEIVED && (
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.receiveButton}
                    onClick={() => onReceiveClick(obj.externalPoNumber)}
                  >
                    Receive
                  </RACButton>
                )}
              </>
            );
          }
        };
        return (
          <React.Fragment key={index}>
            <RACTableRow hover backgroundColor="white">
              <RACTableCell
                onClick={() => onToggleExpand(index)}
                className={clsx(classes.defaultCursor, classes.poNumber)}
              >
                {index === expandedIndex ? <ArrowDown /> : <ArrowRight />}
              </RACTableCell>
              <RACTableCell
                className={clsx(classes.defaultCursor, classes.poNumber)}
              >
                <Link
                  style={{ color: 'inherit', textDecoration: 'unset' }}
                  to={{
                    pathname: `${AppRoute.DetailsNavigation}/${obj.externalPoNumber}/${storeNum}`,
                  }}
                >
                  {obj.externalPoNumber}
                </Link>
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {storeNum}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {trimTextWithTooltip(purchaseOrderDetails.supplierName)}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {getDepartmentById(
                  classificationsByDepts,
                  purchaseOrderDetails
                )?.[0]?.name || PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {getSubDepartmentById(
                  classificationsByDepts,
                  purchaseOrderDetails
                )?.[0]?.name || PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {purchaseOrderDetails.condition}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {trimTextWithTooltip(purchaseOrderDetails.brand)}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {getBracketById(
                  classificationsByDepts,
                  purchaseOrderDetails
                )?.[0]?.name || ''}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {formatMoney(obj.totalCost || '')}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {purchaseOrderDetails.quantityOrdered || ''}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {purchaseOrderStatusTypes.find(
                  (statusType: any) =>
                    statusType.referenceCode === obj.poStatusType
                )?.description || ''}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {generateActions(obj.externalPoNumber)}
              </RACTableCell>
            </RACTableRow>
            {expandedIndex === index && (
              <RACTableRow
                className={classes.details}
                key={`${index}-details`}
                backgroundColor={RACCOLOR.LAVENDER_MIST}
              >
                <RACTableCell className={classes.noPadding} colSpan={13}>
                  <div className={classes.row}>
                    <RACTable
                      renderTableHead={() => (
                        <>
                          <RACTableCell
                            className={classes.defaultCursor}
                          ></RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Supply Order#
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Model
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Cost per Item
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Submitted By
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Submitted Date and Time
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Description
                          </RACTableCell>
                        </>
                      )}
                      // eslint-disable-next-line sonarjs/no-identical-functions
                      renderTableContent={() => (
                        <RACTableRow backgroundColor={RACCOLOR.LAVENDER_MIST}>
                          <RACTableCell
                            className={classes.defaultCursor}
                          ></RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {trimTextWithTooltip(
                              purchaseOrderDetails.supplierOrderNumber || 'NA'
                            )}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {trimTextWithTooltip(
                              purchaseOrderDetails.modelNumber,
                              DESCRIPTION_COLUMN_TEXT_LIMIT
                            )}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {formatMoney(purchaseOrderDetails.itemCost || '')}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {obj.createdBy || '--'}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {new Date(obj.createdDate || '').toLocaleDateString(
                              'en-US',
                              {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                              }
                            )}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {trimTextWithTooltip(
                              purchaseOrderDetails.itemDescription
                            )}
                          </RACTableCell>
                        </RACTableRow>
                      )}
                    />
                  </div>
                </RACTableCell>
              </RACTableRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );

  useEffect(() => {
    if (
      (initialStores.length === 0 ||
        initialStores.length >= GET_STORES_LIMIT ||
        !!purchaseOrders?.length) &&
      !poStores.length &&
      selectedPOCleared
    ) {
      setStoreSelectionIsRequired(true);
      return;
    }
    setStoreSelectionIsRequired(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPOCleared]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchPurchaseOrders = (isFilterChanged = false) => {
    if (
      !poNumber &&
      (initialStores.length === 0 ||
        initialStores.length >= GET_STORES_LIMIT ||
        !!purchaseOrders?.length) &&
      !poStores.length
    ) {
      setStoreSelectionIsRequired(true);
      return;
    }

    setStoreSelectionIsRequired(false);
    setLoading(true);
    setApiHasError(false);

    if (initialCallToken?.current) {
      initialCallToken.current?.cancel();
    }
    initialCallToken.current = getCancelTokenSource();

    const poStoreNumsStrings: string[] = poStores.map(
      (store) => store.storeNum
    );

    const request: GetPurchaseOrderRequest = {
      ...(poStoreNumsStrings?.length && {
        storeNumber: poStoreNumsStrings,
      }),
      purchaseOrderType: ['ER'],
      poStatusType:
        selectedPurchaseOrderType !== 'All'
          ? [selectedPurchaseOrderType]
          : SubmittdPOStatusTypes,
    };

    if (fromDate && toDate) {
      request.poDateRange = {
        endDate: toDate,
        startDate: fromDate,
      };
    }

    if (poNumber) {
      request.coworkerId = user?.employeeId;
      request.purchaseOrderNumber = poNumber;
    }
    getPurchaseOrdersByStatus(
      request,
      offset,
      MANUAL_PO_API_LIMIT,
      initialCallToken.current?.token
    )
      .then((response: GetPurchaseOrderResponse) => {
        const hasPO = !!response.purchaseOrders.length;
        if (hasPO) {
          if (isFilterChanged) {
            setPurchaseOrders(response.purchaseOrders);
          } else {
            setPurchaseOrders([...purchaseOrders, ...response.purchaseOrders]);
          }
        }
        setHasMore(
          hasPO && response?.purchaseOrders?.length === MANUAL_PO_API_LIMIT
        );

        setLoading(false);
      })
      .catch((err: any) => {
        if (!err.__CANCEL__) {
          setApiHasError(true);
          setPurchaseOrders([]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setPurchaseOrders([]);
    setOffset(INITIAL_OFFSET);
    fetchPurchaseOrders(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    poStores,
    initialStores,
    selectedPurchaseOrderType,
    poNumber,
    fromDate,
    toDate,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (reloadPOList) {
      setOffset(INITIAL_OFFSET);
      fetchPurchaseOrders();
      setReloadPOList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadPOList]);

  useEffect(() => {
    if (offset !== INITIAL_OFFSET) {
      fetchPurchaseOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <>
      {openDeleteModal && (
        <DeletePOConfirmModal
          id={selectedPO}
          onClose={handleDeletePOModalClose}
          onResolve={(value) => handleDeletePOModalResolve(value)}
        />
      )}
      {openCancelModal && (
        <CancelPOConfirmModal
          id={selectedPO}
          onClose={handleCancelPOModalClose}
          onResolve={(value) => handleCancelPOModalResolve(value)}
        />
      )}
      {openAlertModal && (
        <AlertModal
          alertMessage={alertMessage}
          alertType={alertType}
          onClose={handleCancelPOModalClose}
        />
      )}

      <RACInfiniteScroll
        offset={offset}
        setOffset={setOffset}
        data={purchaseOrders}
        loading={loading}
        hasMore={hasMore}
        hasApiError={hasApiError}
        customMessage={
          storeSelectionIsRequired ? CUSTOM_API_MESSAGE_FETCH_PO : null
        }
        classes={{
          loader: classes.contentHeight,
          apiError: classes.contentHeight,
          noItems: classes.contentHeight,
        }}
        successContent={
          <RACTable
            renderTableHead={renderTableHead}
            renderTableContent={renderTableContent}
            stickyHeader
          />
        }
      />
    </>
  );
};
