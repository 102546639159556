import { makeStyles } from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { generateCancelToken } from '../../api/client';
import { getPurchaseOrderById } from '../../api/purchaseOrders';
import { getStores } from '../../api/store';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { AppRoute } from '../../config/route-config';
import { PoFormField } from '../../constants/constants';
import { Store } from '../../domain/Store';
import {
  PurchaseOrder,
  PurchaseOrder as PurchaseOrderModel,
  PurchaseOrderFormValues,
} from '../../types/types';
import { toPurchaseOrderFormValues } from '../../utils/purchaseOrderFormMappers';
import { PODetailsForm } from './PODetailsForm';

export type PODetailsProps = RouteComponentProps<
  { purchaseOrderId: string; storeNumber: string },
  Record<string, unknown>
>;

export const useStyles = makeStyles({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
});

export const PODetails = (props: PODetailsProps) => {
  const classes = useStyles();
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderModel | null>(
    null
  );
  const [purchaseOrderFormValues, setPurchaseOrderFormValues] =
    useState<PurchaseOrderFormValues | null>(null);
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [purchaseOrderNotFound, setPurchaseOrderNotFound] = useState(false);
  const [apiError, setApiError] = useState<Error | null>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [searchStoreResult, setSearchStoreResult] = useState<Store[]>([]);
  const {
    match: {
      params: { purchaseOrderId, storeNumber },
    },
    location: { pathname },
  } = props;

  const isCreatePOPath = pathname === AppRoute.Create;

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      try {
        if (!purchaseOrderId) return;

        const request = {
          purchaseOrderNumber: purchaseOrderId,
          storeNumber: [storeNumber],
        };

        const purchaseOrderResponse = await getPurchaseOrderById(request);

        const purchaseOrders: PurchaseOrder[] =
          purchaseOrderResponse?.purchaseOrders?.map((po: any) => {
            return {
              id: po?.externalPoNumber,
              purchaseOrderSubType: po?.purchaseOrderSubType,
              supplier: po?.purchaseOrderDetails[0]?.supplierName,
              brand: po?.purchaseOrderDetails[0]?.brand,
              model: po?.purchaseOrderDetails[0]?.modelNumber,
              itemCondition: po?.purchaseOrderDetails[0]?.condition,
              department: po?.purchaseOrderDetails[0]?.department,
              subDepartment: po?.purchaseOrderDetails[0]?.subDepartment,
              itemType: po?.purchaseOrderDetails[0]?.itemType,
              itemSubType: po?.purchaseOrderDetails[0]?.itemSubType,
              bracket: po?.purchaseOrderDetails[0]?.bracket,
              startClassificationsSelect: PoFormField.Department,
              description: po?.purchaseOrderDetails[0]?.itemDescription,
              supplierOrderNo: po?.purchaseOrderDetails[0]?.supplierOrderNumber,
              totalQuantity: po?.purchaseOrderDetails[0]?.quantityOrdered,
              costPerItem: po?.purchaseOrderDetails[0]?.itemCost,
              totalCost: po?.totalCost,
              allocations: [
                {
                  pricing: po?.purchaseOrderDetails[0]?.pricing,
                  quantity: po?.purchaseOrderDetails[0]?.quantityOrdered,
                  state: po?.state,
                  storeNum: po?.storeNumber,
                },
              ],
              poStatus: po?.poStatusType,
            };
          });

        purchaseOrders?.length
          ? setPurchaseOrder(purchaseOrders[0])
          : setPurchaseOrderNotFound(true);
      } catch (error: any) {
        setIsSaveDraftDisabled(false);
        setIsSubmitDisabled(false);
        setApiError(error);
      }
    };

    if (purchaseOrderId) {
      fetchPurchaseOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderId]);

  useEffect(() => {
    if (isCreatePOPath) {
      setPurchaseOrder({
        ...purchaseOrder,
        poStatus: '',
        id: '',
      } as PurchaseOrderModel);
      setPurchaseOrderFormValues(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (purchaseOrder) {
      setPurchaseOrderFormValues(toPurchaseOrderFormValues(purchaseOrder));
    }
  }, [purchaseOrder]);

  useEffect(() => {
    async function fetchStores() {
      const cancelToken: CancelTokenSource = generateCancelToken();

      if (searchTerm.length >= 3 || searchTerm === '') {
        setIsStoreLoading(true);
        const fetchedStores: Store[] = await getStores(
          searchTerm,
          cancelToken.token
        );
        setIsStoreLoading(false);
        setSearchStoreResult(fetchedStores as Store[]);
      }
    }
    fetchStores();
  }, [searchTerm]);

  return (
    <>
      <>
        {purchaseOrderNotFound && <Redirect to="/not-found" />}
        {apiError && <Redirect to={AppRoute.Index} />}
        {!purchaseOrderId || purchaseOrderFormValues ? (
          <PODetailsForm
            purchaseOrderId={isCreatePOPath ? '' : purchaseOrderId}
            purchaseOrder={purchaseOrderFormValues}
            stores={searchStoreResult}
            isSaveDraftDisabled={isSaveDraftDisabled}
            setIsSaveDraftDisabled={setIsSaveDraftDisabled}
            isSubmitDisabled={isSubmitDisabled}
            setIsSubmitDisabled={setIsSubmitDisabled}
            onSearch={setSearchTerm}
            isSearchLoading={isStoreLoading}
            {...props}
          />
        ) : (
          <ApiStateWrapper
            loading
            hasApiError={purchaseOrderNotFound}
            successContent={<></>}
            classes={{
              loader: classes.contentHeight,
              apiError: classes.contentHeight,
            }}
          />
        )}
      </>
    </>
  );
};
