/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { ArrayField, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getRateAndTerm } from '../../api/purchaseOrders';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import {
  PoFormField,
  PoFormLabel,
  PO_TYPE,
  pricingConfig,
  VALIDATIONS,
} from '../../constants/constants';
import { useUserPermissions } from '../../context/permission/PermissionsProvider';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import {
  FormAllocation,
  FormPricing,
  PurchaseOrderFormValues,
} from '../../types/types';
import { firstStoreNumInState } from '../../utils/formAllocations';
import { PricingCalculator } from '../../utils/PricingCalculator';
import { calculateValue } from '../../utils/utils';
import { DuplicatePoState } from '../PODetails/useDuplicatePo';
import { AlertModal } from '../POs/AlertModal';
import {
  ActionType,
  useAutoPricingDispatch,
} from './context/AutoPricingProvider';
import { PricingElement } from './PricingElement';

export const pricingItemTestId = 'pricingItemComponent';

export interface CommonProps {
  index: number;
  field: Partial<ArrayField<FormPricing>>;
  purchaseOrder: PurchaseOrderFormValues | null;
}

export interface PricingProps extends CommonProps {
  allocations: FormAllocation[];
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
  },
  cardContentContainer: {
    padding: '1.25rem',
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  field: {
    width: theme.typography.pxToRem(170),
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 32,
  },
}));

export const PricingItem = (props: PricingProps) => {
  const classes = useStyles();
  const { register, clearErrors, errors } = useFormContext();

  const { index, field, purchaseOrder, allocations } = props;
  const [isAutoPricingCalled, setIsAutoPricingCalled] = useState(false);
  const [isAutoPricingArrived, setIsAutoPricingArrived] = useState(false);
  const [isPricingEditable, setIsPricingEditable] = useState(false);
  const [hasSuggestPricingError, setHasSuggestPricingError] = useState(false);
  const { setValue, getValues } = useFormContext();
  const { hasAutoPricing, hasEditablePricing } = useUserPermissions();

  const history = useHistory<DuplicatePoState>();

  const isViewMode = !!(
    purchaseOrder && ![PoStatus.DRAFT, ''].includes(purchaseOrder.poStatus)
  );
  const isCanceled: boolean =
    (purchaseOrder && purchaseOrder.poStatus === PoStatus.OPEN_CANCELLED) ||
    false;

  const dispatch = useAutoPricingDispatch();
  const { bracket, department, itemSubType, itemType, subDepartment } =
    getValues();
  const costPerItem = useWatch({ name: 'costPerItem' }) as string;

  const storeNum =
    field.state && firstStoreNumInState(allocations, field.state);

  const weeklyTermWatched = useWatch({
    name: `pricing[${index}].weeklyTerm`,
  }) as string;
  const weeklyRentWatched = useWatch({
    name: `pricing[${index}].weeklyRent`,
  }) as string;
  const totalRtoPriceWatched = useWatch({
    name: `pricing[${index}].totalRtoPrice`,
  }) as string;
  const cashPriceWatched = useWatch({
    name: `pricing[${index}].cashPrice`,
  }) as string;
  const monthlyRentWatched = useWatch({
    name: `pricing[${index}].monthlyRent`,
  }) as string;
  const monthlyTermWatched = useWatch({
    name: `pricing[${index}].monthlyTerm`,
  }) as string;
  const monthlyRateWatched = useWatch({
    name: `pricing[${index}].monthlyRent`,
  }) as string;
  const semiMonthlyTermWatched = useWatch({
    name: `pricing[${index}].semiMonthlyTerm`,
  }) as string;
  const semiMonthlyRateWatched = useWatch({
    name: `pricing[${index}].semiMonthlyRent`,
  }) as string;
  const biWeeklyTermWatched = useWatch({
    name: `pricing[${index}].biWeeklyTerm`,
  }) as string;
  const biWeeklyRentWatched = useWatch({
    name: `pricing[${index}].biWeeklyRent`,
  }) as string;

  useEffect(() => {
    let totalRtoPrice = '';
    let biWeeklyRent = '';
    let biWeeklyTerm = '';
    let monthlyRent = '';
    if (weeklyRentWatched && weeklyTermWatched) {
      totalRtoPrice = calculateValue(
        PricingCalculator.totalRtoPrice,
        weeklyRentWatched,
        weeklyTermWatched
      );
      biWeeklyRent = calculateValue(
        PricingCalculator.biWeeklyRent,
        weeklyRentWatched
      );
      biWeeklyTerm = calculateValue(
        PricingCalculator.biWeeklyTerm,
        weeklyTermWatched
      );
      monthlyRent = calculateValue(
        PricingCalculator.monthlyRent,
        weeklyRentWatched,
        `${PricingCalculator.monthlyMultiplier()}`
      );
    }

    setValue(`pricing[${index}].totalRtoPrice`, totalRtoPrice);
    setValue(`pricing[${index}].biWeeklyRent`, biWeeklyRent);
    setValue(`pricing[${index}].biWeeklyTerm`, biWeeklyTerm);
    setValue(`pricing[${index}].monthlyRent`, monthlyRent);

    clearErrors([
      `pricing[${index}].weeklyRent`,
      `pricing[${index}].weeklyTerm`,
    ]);
  }, [weeklyRentWatched, weeklyTermWatched]);

  useEffect(() => {
    let monthlyTerm = '';
    if (totalRtoPriceWatched && monthlyRentWatched) {
      monthlyTerm = calculateValue(
        PricingCalculator.monthlyTerm,
        totalRtoPriceWatched,
        monthlyRentWatched
      );
    }

    setValue(`pricing[${index}].monthlyTerm`, monthlyTerm);
  }, [totalRtoPriceWatched, monthlyRentWatched]);

  useEffect(() => {
    let semiMonthlyRent = '';
    if (monthlyRentWatched) {
      semiMonthlyRent = calculateValue(
        PricingCalculator.semiMonthlyRent,
        monthlyRentWatched
      );
    }

    setValue(`pricing[${index}].semiMonthlyRent`, semiMonthlyRent);
  }, [monthlyRentWatched]);

  useEffect(() => {
    let semiMonthlyTerm = '';
    if (monthlyRentWatched) {
      semiMonthlyTerm = calculateValue(
        PricingCalculator.semiMonthlyTerm,
        monthlyTermWatched
      );
    }

    setValue(`pricing[${index}].semiMonthlyTerm`, semiMonthlyTerm);
  }, [monthlyRentWatched, monthlyTermWatched]);

  const { pricing } = getValues();
  const { weeklyRent: weeklyRentValue, weeklyTerm: weeklyTermValue } =
    pricing?.[index] || {};

  const fetchSuggestedPricing = async () => {
    setIsAutoPricingCalled(true);
    setIsAutoPricingArrived(false);

    dispatch({
      type: ActionType.SET_AUTO_PRICE_LOADING,
      payload: true,
    });
    const response = await getRateAndTerm({
      bracket,
      costPerItem,
      department,
      itemSubType,
      itemType,
      storeNumber: storeNum as string,
      subDepartment,
      poType: PO_TYPE.MANUAL_PO,
    }).catch(() => {
      setIsAutoPricingArrived(true);
      dispatch({
        type: ActionType.SET_AUTO_PRICE_LOADING,
        payload: true,
      });
    });
    dispatch({
      type: ActionType.SET_AUTO_PRICE_LOADING,
      payload: false,
    });
    setIsAutoPricingArrived(true);
    if (
      !response ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      response?.errors?.length ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      response?.data?.errors?.length
    ) {
      setHasSuggestPricingError(true);
    } else if (response?.result?.[0]?.newRate) {
      const newPricing = response.result[0].newRate;
      const {
        weeklyRate,
        weeklyTerm,
        totalCost,
        cashPrice,
        biWeeklyRate,
        biWeeklyTerm,
        semiMonthlyRate,
        semiMonthlyTerm,
        monthlyRate,
        monthlyTerm,
      } = newPricing;

      setValue(`pricing[${index}].weeklyRent`, weeklyRate);
      setValue(`pricing[${index}].weeklyTerm`, weeklyTerm);
      setValue(`pricing[${index}].totalRtoPrice`, totalCost);
      setValue(`pricing[${index}].cashPrice`, cashPrice);
      setValue(`pricing[${index}].biWeeklyRent`, biWeeklyRate);
      setValue(`pricing[${index}].biWeeklyTerm`, biWeeklyTerm);
      setValue(`pricing[${index}].semiMonthlyRent`, semiMonthlyRate);
      setValue(`pricing[${index}].semiMonthlyTerm`, semiMonthlyTerm);
      setValue(`pricing[${index}].monthlyRent`, monthlyRate);
      setValue(`pricing[${index}].monthlyTerm`, monthlyTerm);
      clearErrors([
        `pricing[${index}].weeklyRent`,
        `pricing[${index}].weeklyTerm`,
      ]);
    }
  };
  useEffect(() => {
    // Draft PO - Do not call auto pricing when it already exists, in order to avoid overriding user's input
    if (
      purchaseOrder?.pricing?.[index]?.weeklyRent &&
      purchaseOrder?.pricing?.[index]?.weeklyTerm &&
      // Covers the case: store is removed and another store is selected
      purchaseOrder?.allocations?.[index]?.storeNum === storeNum &&
      purchaseOrder?.bracket === bracket &&
      purchaseOrder?.costPerItem === costPerItem &&
      purchaseOrder?.department === department &&
      purchaseOrder?.itemSubType === itemSubType &&
      purchaseOrder?.itemType === itemType &&
      purchaseOrder?.subDepartment === subDepartment
    ) {
      return;
    }
    // Duplicate PO - Do not call auto pricing when it already exists, in order to avoid overriding user's input
    if (
      history?.location?.state?.previousValues?.pricing?.[index]?.weeklyRent &&
      history?.location?.state?.previousValues?.pricing?.[index]?.weeklyTerm &&
      // Covers the case: store is removed and another store is selected
      history?.location?.state?.previousValues?.allocations?.[index]
        ?.storeNum === storeNum &&
      history?.location?.state?.previousValues?.allocations?.[index]
        ?.storeNum === storeNum &&
      history?.location?.state?.previousValues?.bracket === bracket &&
      history?.location?.state?.previousValues?.costPerItem === costPerItem &&
      history?.location?.state?.previousValues?.department === department &&
      history?.location?.state?.previousValues?.itemSubType === itemSubType &&
      history?.location?.state?.previousValues?.itemType === itemType &&
      history?.location?.state?.previousValues?.subDepartment === subDepartment
    ) {
      return;
    }
    if (
      bracket &&
      costPerItem &&
      department &&
      itemSubType &&
      itemType &&
      storeNum &&
      subDepartment &&
      hasAutoPricing
    )
      fetchSuggestedPricing();
  }, [
    field.state,
    index,
    setValue,
    bracket,
    costPerItem,
    department,
    itemSubType,
    itemType,
    storeNum,
    subDepartment,
  ]);

  const checkIfPricingIsEditable = () => {
    if (isCanceled) {
      return false;
    }
    // view existing PO details
    if (isViewMode) {
      return false;
    }

    // create, edit and duplicate
    if (!hasAutoPricing) {
      return true;
    }

    if (!hasEditablePricing) {
      return false;
    }
    // create and edit
    if (isAutoPricingCalled) {
      return isAutoPricingArrived;
    }

    return true;
  };

  useEffect(() => {
    setIsPricingEditable(checkIfPricingIsEditable());
  }, [
    isViewMode,
    hasAutoPricing,
    hasEditablePricing,
    isAutoPricingCalled,
    isAutoPricingArrived,
    isCanceled,
  ]);

  return useMemo(
    () => (
      <div key={index} data-testid={pricingItemTestId}>
        {hasSuggestPricingError && (
          <AlertModal
            onClose={() => setHasSuggestPricingError(false)}
            alertType="error"
            alertMessage={`Unable to suggest pricing for store ${storeNum}!`}
            serverResponse=""
          />
        )}
        <div className={clsx(classes.row, classes.flexStart)}>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.WeeklyRate}
              title={PoFormLabel.WeeklyRate}
              currency
              errorMessage={
                errors?.pricing &&
                errors?.pricing?.[index]?.[PoFormField.WeeklyRent]
                  ? VALIDATIONS[PoFormField.WeeklyRent]
                  : ''
              }
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].weeklyRent`}
                required
                disabled={!isPricingEditable}
                min={pricingConfig[PoFormField.WeeklyRent].validations.min}
                max={pricingConfig[PoFormField.WeeklyRent].validations.max}
                defaultValue={weeklyRentWatched}
              />
            </FieldWithTooltip>
          </span>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.WeeklyTerm}
              title={PoFormLabel.WeeklyTerm}
              errorMessage={
                errors?.pricing &&
                errors?.pricing?.[index]?.[PoFormField.WeeklyTerm]
                  ? VALIDATIONS[PoFormField.WeeklyTerm]
                  : ''
              }
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].weeklyTerm`}
                required
                disabled={!isPricingEditable}
                min={pricingConfig[PoFormField.WeeklyTerm].validations.min}
                max={pricingConfig[PoFormField.WeeklyTerm].validations.max}
                defaultValue={weeklyTermWatched}
                decimalPlaces={0}
              />
            </FieldWithTooltip>
          </span>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.TotalRTOPrice}
              title={PoFormLabel.TotalRTOPrice}
              currency
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].totalRtoPrice`}
                required
                disabled
                defaultValue={totalRtoPriceWatched}
              />
            </FieldWithTooltip>
          </span>
        </div>
        <div className={clsx(classes.row, classes.flexStart)}>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.BiWeeklyRate}
              title={PoFormLabel.BiWeeklyRate}
              currency
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].biWeeklyRent`}
                required
                disabled
                defaultValue={biWeeklyRentWatched}
              />
            </FieldWithTooltip>
          </span>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.BiWeeklyTerm}
              title={PoFormLabel.BiWeeklyTerm}
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].biWeeklyTerm`}
                disabled
                required
                defaultValue={biWeeklyTermWatched}
                decimalPlaces={0}
              />
            </FieldWithTooltip>
          </span>
        </div>
        <div className={clsx(classes.row, classes.flexStart)}>
          <span>
            <FieldWithTooltip
              id={PoFormLabel.SemiMonthlyRate}
              title={PoFormLabel.SemiMonthlyRate}
              currency
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].semiMonthlyRent`}
                required
                disabled
                defaultValue={semiMonthlyRateWatched}
              />
            </FieldWithTooltip>
          </span>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.SemiMonthlyTerm}
              title={PoFormLabel.SemiMonthlyTerm}
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].semiMonthlyTerm`}
                required
                disabled
                defaultValue={semiMonthlyTermWatched}
                decimalPlaces={0}
              />
            </FieldWithTooltip>
          </span>
        </div>
        <div className={clsx(classes.row, classes.flexStart)}>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.MonthlyRate}
              title={PoFormLabel.MonthlyRate}
              currency
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].monthlyRent`}
                required
                disabled
                defaultValue={monthlyRateWatched}
              />
            </FieldWithTooltip>
          </span>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.MonthlyTerm}
              title={PoFormLabel.MonthlyTerm}
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].monthlyTerm`}
                required
                disabled
                defaultValue={monthlyTermWatched}
                decimalPlaces={0}
              />
            </FieldWithTooltip>
          </span>
          <span className={classes.field}>
            <FieldWithTooltip
              id={PoFormLabel.CashPrice}
              title={PoFormLabel.CashPrice}
              currency
            >
              <PricingElement
                purchaseOrder={purchaseOrder}
                fieldName={`pricing[${index}].cashPrice`}
                disabled
                defaultValue={cashPriceWatched}
              />
            </FieldWithTooltip>
          </span>
        </div>
        <input
          type="hidden"
          name={`pricing[${index}].state`}
          ref={register({ required: true })}
          defaultValue={field.state}
          value={field.state}
        />
      </div>
    ),
    [
      index,
      errors?.pricing,
      purchaseOrder,
      hasAutoPricing,
      hasEditablePricing,
      isAutoPricingCalled,
      weeklyRentValue,
      weeklyRentWatched,
      weeklyTermValue,
      weeklyTermWatched,
      totalRtoPriceWatched,
      biWeeklyTermWatched,
      semiMonthlyRateWatched,
      semiMonthlyTermWatched,
      monthlyRateWatched,
      monthlyTermWatched,
      isPricingEditable,
      hasSuggestPricingError,
    ]
  );
};
