export enum AppRoute {
  Index = '/',
  IndexWithAppName = '/manualpo',
  POs = '/manualpo/pos',
  // used by router
  Details = '/manualpo/details/:purchaseOrderId/:storeNumber',
  DraftDetails = '/manualpo/draftDetails/:purchaseOrderId',
  // used when user click on PO
  DetailsNavigation = '/manualpo/details',
  DraftDetailsNavigation = '/manualpo/draftDetails',
  Create = '/manualpo/create',
  ReceivingPO = '/receivingpo/purchaseorders',
}
